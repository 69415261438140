import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { TypewriterEffectSmooth } from "../Components/TypewriterEffect";

export function System() {
  const words = [
    { text: "Enhance", className: "text-white dark:text-white" },
    { text: "patient", className: "text-white dark:white" },
    { text: "care", className: "text-white dark:white" },
    { text: "with", className: "text-white dark:white" },
    { text: "Bloom Health", className: "text-blue-500 dark:text-blue-500" },
  ];

  return (<>
  <Navbar />
    <div className="relative flex flex-col items-center justify-center h-screen bg-cover bg-center">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover z-0"
        style={{ filter: "brightness(40%)" }}
      >
        <source src="/system_video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Foreground Content */}
      <div className="relative z-10 flex flex-col items-center justify-center h-[40rem] text-center">
        {/* Introductory text */}
        <p className="text-white dark:text-white text-xs sm:text-base">
          Providing high-quality patient care through advanced Patient and Hospital Management Systems.
        </p>
        
        {/* Typewriter Effect for dynamic headline */}
        <TypewriterEffectSmooth words={words} />

        {/* Call-to-action buttons */}
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 mt-6">
          <button className="w-40 h-10 rounded-xl bg-black border dark:border-white text-white text-sm">
            Access PMS
          </button>
          <button className="w-40 h-10 rounded-xl bg-white text-black border border-black text-sm">
            Access HMS
          </button>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}
