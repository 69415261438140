import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet-async";
import NotFound from "./Pages/NotFound";
import { System } from "./Pages/System";
import Maintenance from "./Pages/Maintenance";

// Lazy load pages for better performance
const Home = lazy(() => import("./Pages/Home"));
const Legal = lazy(() => import("./Pages/Legal"));
const Appointment = lazy(() => import("./Pages/Appointment"));
const AboutUs = lazy(() => import("./Pages/AboutUs"));
const Services =  lazy(() => import("./Pages/Services"));

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Router basename="/">
          {/* Add Suspense for lazy-loaded components */}
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* <Route path="/" element={<Maintenance />} /> */}
               <Route
                path="/"
                element={
                  <>
                    <Helmet>
                      <title>Home - Bloom Health</title>
                      <meta name="description" content="Enhance patient care with Bloom Health" />
                    </Helmet>
                    <Home />
                  </>
                }
              /> 
               <Route
                path="/legal"
                element={
                  <>
                    <Helmet>
                      <title>Legal Information - Bloom Health</title>
                      <meta name="description" content="Legal terms and conditions for using our medical services." />
                    </Helmet>
                    <Legal />
                  </>
                }
              />
              <Route
                path="/appointment"
                element={
                  <>
                    <Helmet>
                      <title>Book an Appointment - Bloom Health</title>
                      <meta name="description" content="Schedule an appointment with our medical professionals." />
                    </Helmet>
                    <Appointment />
                  </>
                }
              />
              <Route
                path="/system"
                element={
                  <>
                    <Helmet>
                      <title>System - Bloom Health</title>
                      <meta name="description" content="Access the advanced Patient and Hospital Management System." />
                    </Helmet>
                    <System />
                  </>
                }
              />
               <Route
                path="/about"
                element={
                  <>
                    <Helmet>
                      <title>About - Bloom Health</title>
                      <meta name="description" content="Enhance patient care with Bloom Health." />
                    </Helmet>
                    <AboutUs />
                  </>
                }
              />
              <Route
                path="/services"
                element={
                  <>
                    <Helmet>
                      <title>Services - Bloom Health</title>
                      <meta name="description" content="Enhance patient care with Bloom Health." />
                    </Helmet>
                    <Services />
                  </>
                }
              /> 
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
