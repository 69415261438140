import React from "react";
import { useLocation } from "react-router-dom";

function Maintenance() {

  return (
    <div>
      <h1>Work In Progress</h1>
      <p>Stay tuned for latest update from bloom health</p>
      <p>We will get back to you soon.</p>
    </div>
  );
}

export default Maintenance;
