const DropdownDialog = ({ isOpen, submenu }) => {
  if (!isOpen || !submenu) return null;  // Ensure submenu exists

  return (
    <div
      className="absolute left-0 w-full bg-white shadow-lg p-6 z-50 transition-all duration-300 rounded-b-lg"
      style={{
        top: "calc(100%)",
        transform: isOpen ? "translateY(0)" : "translateY(-10px)",
        opacity: isOpen ? 1 : 0,
      }}
    >
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 pt-2 justify-items-center">
        {submenu.map((location, index) => (
          <div key={index} className="text-gray-700 text-left"> {/* Keep text-left for content */}
            <div className="font-semibold text-black text-md mb-3 hover:text-gray-600 transition-colors duration-200">
              {location.title}
            </div>
            <div className="mt-3 space-y-2">
              {location.menus.map((hospital, hospitalIndex) => (
                <a
                  key={hospitalIndex}
                  href={hospital.link}
                  className="block text-gray-600 text-sm hover:text-blue-500 transition-colors duration-200 text-left"
                >
                  {hospital.name}
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownDialog;
